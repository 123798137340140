import * as React from "react";
import { LabelContext } from "../core/label.context";

import { GeneralContext } from "../core/general.context";
import { BRX_HOST } from "../core/APIUtils";
export const PopUpImages = (props: any) => {
    const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);


    return <>
        <div className="popUpImages">
            <div className="close" onClick={ () =>  props.onClose() }><img src={BRX_HOST + "/binaries/content/gallery/smeg-italy/landing-ped/icons/close.png"} /></div>
            {
             props.images.map((image:any, i:any) => 
             {
                if(i === selectedImageIndex){
                    return (
                        <div className="selectedImage">
                        <img  src={image?.midResUrl}  />
                        </div>
                    )
                }
             } 
             )
            }
            <div className="othersImage">
            {
             props.images.map((image:any, i:any) => 
             {
                if(i != selectedImageIndex){                    
                    return (
                        <div className="otherImage" onClick={ () => setSelectedImageIndex(i) }>
                            <div className="imgBorder">                        
                                <img src={image?.midResUrl}  />
                            </div>
                        </div>
                    )
                }
             } 
             )
            }
            </div>
            <div className="sku">{props.sku}</div>
        </div>
    </>
};


import * as React from "react";
import { LabelContext } from "../core/label.context";

import { GeneralContext } from "../core/general.context";
import { BRX_HOST } from "../core/APIUtils";

export const Menu = (item: any) => {
    const [urlShare, setUrlShare] = React.useState("");
    const [closePopUp, setClosePopUp] = React.useState(false);
    const { t } = React.useContext(LabelContext);
    const { isMobile, setIsMobile, start, handleBack, changeUrl, setSelectedColor, setSelectedFamily, selectedFamily, selectedColor, selectedSku, setSelectedSku } = React.useContext(GeneralContext);
    const [width, setWidth] = React.useState<number>(window.innerWidth);
    let shareText: string;
    React.useEffect(() => {
        shareText = t("shareMessage") + " - " + encodeURIComponent(window.location.href);
        setUrlShare("https://api.whatsapp.com/send?text=" + shareText)

    }, [changeUrl])



    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
        setIsMobile(width <= 768)
    }, []);

    const _isMobile = width <= 768;
    setIsMobile(_isMobile)

    return <>
        <section id="landing_Menu" className="menu">
            <div className="header_landing">
                <div className="back" onClick={() => handleBack()}>
                    {start == 1 && <img width="65" height="65" src={BRX_HOST + "/binaries/content/gallery/smeg-italy/landing-ped/icons/back.png"} />}
                    {start == 0 && <img width="65" height="65" src={BRX_HOST + "/binaries/content/gallery/smeg-italy/landing-ped/icons/black.png"} />}
                </div>
                <div className="logo" onClick={() => { setSelectedFamily(null); setSelectedColor(null); setSelectedSku(null) }}>
                    <img src={BRX_HOST + "/binaries/content/gallery/smeg-italy/landing-ped/icons/logo.png"} className="header__mobileImg brand__img" title="Smeg logo" alt="logo" />
                </div>
                {selectedFamily && urlShare && <div className="share"><a onClick={() => {

                    navigator.share({
                        title: document.title,
                        text: t("shareMessage"),
                        url: window.location.href,
                    })

                }}><img width="65" height="65" src={BRX_HOST + "/binaries/content/gallery/smeg-italy/landing-ped/icons/share.png"} /></a></div>}
                {(!selectedFamily || !urlShare) && <img width="65" height="65" src={BRX_HOST + "/binaries/content/gallery/smeg-italy/landing-ped/icons/black.png"} />}
                {/* <div className="share-option">
                    <div className="share-option-whatsapp">whatsapp</div>
                    <div className="share-option-mail">mail</div>
                </div> */}
            </div>
            {!_isMobile && <div className="messageMobile"><div className="title">{t('titleMobile')}</div><div className="content">{t('messageMobile')}<img width="300" src={t('messageMobileUrlQRcode')} /> </div></div>}
        </section>


    </>
};


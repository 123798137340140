import * as React from "react";
import { LabelContext } from "../core/label.context";

import { GeneralContext } from "../core/general.context";

export const Filter = (props: any) => {

    // Se non c'è immagine suo backgroundColor come sfondo

    let icon_size = 70
    let inline_style = { color:"#3C3C3B", backgroundColor: (!props.image ? props.backgroundColor : "")}

    if(props.selected){
        inline_style.backgroundColor = "#85ADD6"
    }
    if(props.className.indexOf("selected_element_family") > -1){
        icon_size = 50
    }
    return <>
        <div   onClick={ () => props.onClick() } style={props.style} className={props.className}>        
            <div className="content" >
                {props.className!="filters_item_box" && 
                    <>
                    <span>{props.label} </span> 
                        <span className="iconFamily"> 
                    {props.image &&
                            <img height={icon_size} width={icon_size} src={props.image} /> 
                    }
                        </span>
                    </>
                }
                {props.className=="filters_item_box" && 
                    <>
                        <span className={"iconFamily"}  >
                            {props.image &&
                                 <img className={(props.selected ? "selected" : "") } height="70" width="70" src={props.image} /> 
                            }
                            {!props.image &&
                                 <span style={inline_style} className="placeholderColor" /> 
                            }
                         </span>
                    
                    <span>{props.label} </span> 
                    </>
                }
            </div>
        </div>
    </>
};


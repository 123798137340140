import React from "react";
import { ILabelContext } from "../common/common.models";
import { LABELS_URL } from "./APIUtils";

export const LabelContext = React.createContext<ILabelContext>(null!);

export const LabelContextComponent = (props: any) => {

  const [labels, setLabels] = React.useState<any>(null);

  /* === FUNZIONI === */

  const init = () => {
    const requestOptions = {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    }

    fetch(LABELS_URL,requestOptions)
        .then(res=>res.json())
        .then((result)=>{
              setLabels(result);
            },
            (error)=>{
              console.log(error);
            })
  };

  const t = (key: string) => {
    let result = "???" + key + "???";
    if (labels) {
      let labelFound = labels.find((e: { key: string; }) => e.key === key);
      if (labelFound && labelFound.value !== null && labelFound.value !== undefined) {
        result = labelFound.value;
      }
    }
    return result;
  }

  React.useEffect(() => {
    init();
  }, []);

  return (
    <LabelContext.Provider value={{
      t: t
    }}>
      {props.children}
    </LabelContext.Provider>
  );
};
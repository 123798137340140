import * as React from "react";
import ReactDOM from "react-dom";
import './scss/App.scss';

import { GeneralContextComponent } from './core/general.context';
import { GeneralContext } from "./core/general.context";
//import { FilterItems, FileterSummary } from "./components/filter.component";
import { Filter } from "./components/filter.component";
import { PopUpImages } from "./components/popupimages.component";
import { ProductItems } from "./components/products.component";
import { Menu } from "./components/menu.component";
import { BannerTop } from "./components/bannerTop.component";
import { LabelContext, LabelContextComponent } from "./core/label.context";
import { getShortDescription, BRX_HOST, RETAILERS } from "./core/APIUtils";


function App() {

        const search = window.location.search;
    const params = new URLSearchParams(search);
    const p_category = params.get('category');
    const p_color = params.get('color');
    const p_sku = params.get('sku');
    const p_retailer = params.get('retailer');
    

    return (
        <>
            <LabelContextComponent>
                <GeneralContextComponent>
                    <Landing startCategory={p_category} startColor={p_color} startSku={p_sku} retailer={p_retailer}  />
                </GeneralContextComponent>
            </LabelContextComponent>
        </>
    );
}

        const hexToRgb = (hex:any) => {
              // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
              var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
              hex = hex.replace(shorthandRegex, function(m:string, r:string, g:string, b:string) {
                return r + r + g + g + b + b;
              });

              var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
              return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
              } : {r:0, g:0 , b:0 };
            }

export const Landing = (props:any) => {
    const container = document.getElementById("landing-palette-root");



 

    // NEW VERSION
    const { isMobile, allColorObject, allFamilyObject, products, colors, families, selectedSku, setSelectedSku, start,setStart , models, selectedFamily, setSelectedFamily, selectedColor, setSelectedColor, setSelectedRetailer } = React.useContext(GeneralContext);

    const { loading } = React.useContext(GeneralContext);
    const [uniqueCategory, setUniqueCategory] = React.useState([]);
    let [productsNumber, setProductsNumber] = React.useState(3);
    const { t } = React.useContext(LabelContext);

    const [isFamilyGroupFooterOpen, setIsFamilyGroupFooterOpen] = React.useState(false);
    const [isColorGroupFooterOpen, setIsColorGroupFooterOpen] = React.useState(false);
    const [popUpImageIsOpen, setPopUpImageIsOpen] = React.useState(false);
    const [linkRetailer, setLinkRetailer] = React.useState();
    const [backgroundColorSelectedProduct, setBackgroundColorSelectedProduct] = React.useState();




    React.useEffect(() => {
       
        if(families && colors && products && families.length > 0 && products.length > 0 && colors.length > 0){
            if(props.startCategory){
                let selecet_family = families.find( (family:any) =>  family.value == props.startCategory )
                if(selecet_family){                
                    setStart(1)
                    setSelectedFamily(selecet_family)
                }
            }

            if(props.startColor){
                let selected_color = colors.find( (color:any) =>  color.value == props.startColor )
                if(selected_color){                
                    setStart(1)
                    setSelectedColor(selected_color)
                }
            }

            if(props.startSku){
                setStart(1)
                setSelectedSku(props.startSku)
            }

            if(props.retailer){                
                setSelectedRetailer(props.retailer)
                
                if(RETAILERS[props.retailer]){
                    setLinkRetailer(RETAILERS[props.retailer])
                }
            }            
       }
       
    }, [families, colors, products ]);    


    let colorLabel = t("colorLabel") || "";
    let colorTitle = t("colorTitle") || "";
    //let colorSubTitle = t("colorSubTitle") || "";

    let categoryLabel = t("categoryLabel") || "";
    let categoryTitle = t("categoryTitle") || "";
    //let categorySubTitle = t("categorySubTitle") || "";

    let productsFound = t("productsFound");

    let style_for_selected_color:any; 
    if(selectedColor){
        
        
        if(selectedColor.value==="all"){
            style_for_selected_color = {             
                backgroundColor: "#fff",
                backgroundSize: "cover",
                color: (selectedColor.textWhite ? '#FFF' : '#000')
            }
        }else{            
            style_for_selected_color = { 
                backgroundRepeat: "repeat-x",
                backgroundImage: "url('" + selectedColor.disabledImage?.replace('/brx%3AdoubleDeskLarge','') + "')",
                backgroundColor: selectedColor.backgroundColor,
                backgroundSize: "cover",
                color: (selectedColor.textWhite ? '#FFF' : '#000')
            }
        }
    }


    if (!container)
        return null;
    else {
        
        let listColors:any[] = [];
        let listColorsOption:any[] = []

        if(!loading && start==1 && selectedFamily){
            models.filter( (model:any) => (model.family==selectedFamily.value || selectedFamily.value=="all" )).forEach(
                (model:any) => {                    
                    model?.products.forEach( (proudct:any) => {
                        listColors.push(proudct.color);
                    })
            })
            listColorsOption = colors.filter((color:any) => (listColors.includes(color.value)))
            
        }

        
        let listOfProducs:any[] = [];
        let listOfProducsModelCode:String[] = [];

        if(models && selectedColor){

            models.forEach( (model:any) => {
                
                if(model.family === selectedFamily.value || selectedFamily.value==="all"){
                
                    model.products.forEach( (product:any) => {
                            
                            if(product.color==selectedColor.value || selectedColor.value=="all"){
                                if(!listOfProducsModelCode.includes(product.model) || selectedColor.value=="all"){               
                                    
                                    
                                    product.description = getShortDescription(product.description, product.family, product.model);
                                    let tmp_desc = t(product.model)
                                    if(tmp_desc.indexOf("???") == -1)
                                       product.description  = tmp_desc
                                    listOfProducs.push( product );
                                    listOfProducsModelCode.push(product.model)
                                }
                            }
                        }
                    )
                }
            })
        

        }
        let selectedProduct:any;
        let inline_style_product_selected_buyonline:any;
        if(selectedSku){
            selectedProduct = products.find((product:any) => (product.sku==selectedSku))
            selectedProduct.description = getShortDescription(selectedProduct.description, selectedProduct.family, selectedProduct.model);
            let tmp_desc = t(selectedProduct.model)
            if(tmp_desc.indexOf("???") == -1)
                selectedProduct.description  = tmp_desc
            

            if(models && selectedProduct.model){                
                models.find( (model:any) => (model.model === selectedProduct.model)).products.map((product:any) => {

                    if(product.sku==selectedProduct.sku){
                        
                        
                        if(product.backgroundColor=="" || product.backgroundColor==null)
                            product.backgroundColor="#6a6a6a"
                        let bgcolor = hexToRgb(product.backgroundColor )
                        
                        let luminance = (0.299 * bgcolor.r + 0.587 * bgcolor.g + 0.114 * bgcolor.b)/255;
                        if (luminance > 0.5){

                            inline_style_product_selected_buyonline = { color: "#000", backgroundColor: product.backgroundColor }
                        }else{

                            inline_style_product_selected_buyonline = { color: "#FFF", backgroundColor: product.backgroundColor }
                        }                        
                    }
                }
                )
            }
             
            //console.log(products)
        }




        return ReactDOM.createPortal(
            <>
                <div className="page_landing_palette">
                    <Menu />
                    { start==0 &&
                        <BannerTop />
                    }
                    

                    {!loading && start==1 &&
                            <>
                                <section id="product_filters_container" className="landingSection" data-section="2">
                                { !selectedFamily && !selectedProduct &&
                                    <div className="filters_group family" >
                                        <div className="cmp_heading" >
                                            {categoryTitle && <h2>{categoryTitle}</h2>}                                            
                                        </div>
                                        { 
                                            (families.length > 0) && families.map((family:any) => {

                                                

                                                return (
                                                    <Filter 
                                                        style={ {backgroundColor: family.categoryBackgroundColor, borderColor: family.categoryBackgroundColor  } }
                                                        className={ family.category_code  +  " filters_item "} 
                                                        onClick={ () => setSelectedFamily(family)} 
                                                        label={family.name} 
                                                        image={family.activeImage} 
                                                    />                                                    
                                                );
                                            })
                                        }
                                        <Filter 
                                            className={"all filters_item "} 
                                            onClick={ () => setSelectedFamily( allFamilyObject ) } 
                                            image={allFamilyObject.activeImage} 
                                            label={t('familyAll')}                                            
                                        />  
                                        
                                    </div>
                                }


                                { selectedFamily && !selectedColor &&
                                    <div id="filters_colors" className="filters_group colors">
                                        <div className="cmp_heading" >
                                            {colorTitle && <h2>{colorTitle}</h2>}                                            
                                        </div>
                                        <div className="filters_content" id="filters_content_colors_2">
                                            {(loading == 0) && listColorsOption.map((filter: { name: any; value: any; disabledImage:any; backgroundColor: any; textWhite:any; }, i: number) => {

                                                let inline_style:any;
                                                if(filter.disabledImage){
                                                    inline_style = { backgroundSize: "cover",  color:'#00000',  backgroundRepeat: "repeat-x", backgroundImage: "url('" + filter.disabledImage.replace('/brx%3AdoubleDeskLarge','') + "')" }
                                                }else{
                                                    inline_style = { color:'#00000', backgroundColor: filter.backgroundColor }                                                    
                                                }
                                                if(filter.textWhite){
                                                    inline_style.color = '#FFFFFF'   
                                                }
                                                return (
                                                    <Filter 
                                                        className={"filters_item"} 
                                                        onClick={ () => setSelectedColor(filter)} 
                                                        label={filter.name} 
                                                        style={inline_style}  
                                                    />                                                    
                                                )
                                            }
                                            )}
                                            <Filter 
                                                className={"filters_item all"} 
                                                onClick={ () => setSelectedColor( allColorObject )} 
                                                label={t('colorAll')}
                                                style={
                                                    { 
                                                        backgroundSize: "cover",  
                                                        color:'#00000',  
                                                        backgroundRepeat: "repeat-x", 
                                                        backgroundImage: "url('" + allColorObject.disabledImage + "')" 
                                                    }
                                                }  
                                            />
                                    
                                        </div>

                                        
                                    </div>
                                }
                                
                            </section>

                            { /* Lista Modelli */  }
                            {selectedColor && selectedFamily &&
                                <section className="product_list_container landingSection listContainer" data-section="3">
                                    <div className="listModels">                                    
                                    {selectedColor && selectedFamily && !selectedProduct && listOfProducs.map((product:any) => {
                                        if(product.sku.indexOf("DG") == -1){

                                        return (
                                            <>
                                                <div className="product-content" key={product.model}>
                                                    <div className="product-img" onClick={ () => {setSelectedSku(product.sku); setIsFamilyGroupFooterOpen(false); setIsColorGroupFooterOpen(false); } }>
                                                        <img src={product?.image} alt={product.sku} />
                                                    </div>
                                                    <div className="product-description">
                                                        <div className="name">{product.description}</div>                                                                    
                                                    </div>                                                    
                                                </div>
                                            </>
                                        )
                                        }
                                        
                                            }
                                        )                                
                                    }

                                    </div>

                                    { /* Famiglie selezionabili - Quelle compatibili con il colore selezionato */ }    
                                    <div className="filters_footer_wrapper">
                                    <div className="filters_footer">
                                    
                                        <div className={"filters_group_footer filters_group " +  (isFamilyGroupFooterOpen==true ? "filters_open": "filters_close")} >
                                        {selectedColor && selectedFamily && !selectedProduct && <>
                                            <Filter 
                                                backgroundColor={selectedFamily.backgroundColor}
                                                className={ selectedFamily.category_code + " selected_element selected_element_family rounded_top "} 
                                                onClick={ () => { setIsFamilyGroupFooterOpen(!isFamilyGroupFooterOpen);} } 
                                                label={selectedFamily.name} 
                                                image={selectedFamily.activeImage}                                                             
                                                style={{backgroundColor: '#FFF'} }

                                            />
                                            </> 
                                        }
                                        {selectedFamily &&
                                            <>
                                            <div style={ {backgroundColor: selectedFamily.categoryBackgroundColor } } className={"filters_items " + (isFamilyGroupFooterOpen==false ? "hide ": " ")  + selectedFamily.category_code}>
                                            {selectedColor && selectedFamily && !selectedProduct && families.filter(
                                                    (family:any) => {
                                                    return products.find( 
                                                        (product:any) => {                                                
                                                            return ((product.family==family.value && product.color==selectedColor.value) || selectedColor.value=="all" );
                                                        } 
                                                    )
                                                 } 
                                                )
                                                .map(
                                                    (family:any) => { 
                                                        return (
                                                            <>
                                                                <Filter 

                                                                    className={"filters_item_box"} 
                                                                    onClick={ () => {setSelectedFamily(family); setIsFamilyGroupFooterOpen(false);} } 
                                                                    label={family.name} 
                                                                    image={family.activeImage}   
                                                                    selected={(selectedFamily.value==family.value)}
                                                                />
                                                            </>
                                                        )
                                                    }
                                                )
                                            }

                                            { !selectedProduct &&
                                                <Filter 
                                                    className={"filters_item_box"} 
                                                    onClick={ () => {setIsFamilyGroupFooterOpen(false); setSelectedFamily( allFamilyObject ) }} 
                                                    label={t("familyAll")}
                                                    image={allFamilyObject.activeImage} 

                                                /> 
                                            }
                                            </div>

                                            </>
                                            }
                                        </div>
                                    

                                    { /* Colori selezionabili - Quelli compatibili con la categoria selezionata */ }
                                    <div className={"filters_group_footer filters_group color " +  (isColorGroupFooterOpen==true ? "filters_open": "filters_close") } >
                                    {selectedColor && selectedFamily && !selectedProduct && <>
                                        
                                       <Filter 
                                            className={"rounded_top selected_element selected_element_family"} 
                                            onClick={ () => setIsColorGroupFooterOpen(!isColorGroupFooterOpen) } 
                                            label={selectedColor.name} 
                                            image={selectedColor.value==="all" ? selectedColor.activeImage : ""}    
                                            style={style_for_selected_color} 
                                            backgroundColor={selectedColor.backgroundColor}

                                            
                                        />
                                        </> 
                                    }
                                    <div className={"filters_items " + (isColorGroupFooterOpen==false ? "hide": "") }>
                                        
                                        {selectedColor && selectedFamily && !selectedProduct &&
                                            colors
                                            .filter(
                                                (color:any) => {
                                                return products.find( 
                                                    (product:any) => {                                                
                                                        return ((product.family==selectedFamily.value && product.color==color.value) || selectedFamily.value=="all");
                                                    } 
                                                )
                                             } 
                                            )
                                            .map(
                                                (color:any) => { 
                                                    let inline_style:any;
                                                    
                                                    return (
                                                        <>
                                                        <Filter 
                                                            className={"filters_item_box"} 
                                                            onClick={ () => {setSelectedColor(color); setIsColorGroupFooterOpen(false);} } 
                                                            label={color.name} 
                                                            style={inline_style}
                                                            backgroundColor={color.backgroundColor}
                                                            image={color.activeImage?.replace('/brx%3AdoubleDeskLarge','')}
                                                            selected={(selectedColor.value==color.value)}
                                                        />
                                                        </>
                                                    )
                                                }
                                            )
                                        }   

                                        <Filter 
                                                className={"filters_item_box"} 
                                                onClick={ () => { setIsColorGroupFooterOpen(false); setSelectedColor( allColorObject )} } 
                                                label={t("colorAll")}
                                                backgroundColor={allColorObject.backgroundColor}
                                                image={allColorObject.activeImage} 
                                            /> 
                                        </div>                             
                                    </div>                             
                                    </div>
                                    </div>
                                </section>
                            }

                            {selectedProduct &&
                                <>

                                {props.retailer && linkRetailer && <div style={inline_style_product_selected_buyonline} className="retailer"><a target="_blank" href={linkRetailer}>{t("buyOnline")}</a></div>}
                                <section  className="landingSection productDetail" data-section="4">
                                    
                                    <div className="zoom"><img onClick={ () => setPopUpImageIsOpen(true)  } src={ BRX_HOST + "/binaries/content/gallery/smeg-italy/landing-ped/icons/zoom.png"} /></div>
                                    <div className="mainImage" >
                                        <img  src={selectedProduct?.image} alt={selectedProduct.sku} />
                                    </div>
                                    { popUpImageIsOpen && 
                                        <PopUpImages sku={selectedProduct.sku} onClose={ () => setPopUpImageIsOpen(false)} images={selectedProduct.images} />                                        
                                    }

                                    { !popUpImageIsOpen && 
                                        <>
                                            <div className="description">{selectedProduct.description}</div>
                                            <div className="sku" dangerouslySetInnerHTML ={{__html: selectedProduct.sku}} />
                                            <div className="basicInfo">
                                            
                                                { Object.keys(selectedProduct.basicInfo).map((infoKey:any, i:any) => {
                                                    let info = selectedProduct.basicInfo[infoKey]

                                                    return (
                                                        <>
                                                          
                                                            <span className={(i==Object.keys(selectedProduct.basicInfo).length-1) ? "label last" : "label"  }>{info.label}</span>
                                                            <span className={(i==Object.keys(selectedProduct.basicInfo).length-1) ? "txt last" : "txt"  }>{info.txt} </span>
                                                          
                                                        </>
                                                        )
                                                })}
                                            </div>

                                            <div className="section-title">{t("availableColor")}</div>
                                            <div className="product-colors-container">
                                            {models.find( (model:any) => (model.model === selectedProduct.model)).products.map( (product:any) =>
                                                {
                                                let inline_style:any;
                                                if(product.colorImage){
                                                    inline_style = { backgroundImage: "url('" + product.colorImage + "')" }
                                                }else{
                                                    inline_style = { backgroundColor: product.backgroundColor }
                                                    
                                                }
                                                
                                                   return (
                                                    <span style={inline_style}  className={"product-color " + (product.sku==selectedProduct.sku ? "selected" : "")} onClick={ () => {setSelectedSku(product.sku);  window.scrollTo(0, 0)} }>
                                                    
                                                    </span>
                                                    )
                                                }
                                                )
                                            }
                                         </div>
                                        </>
                                    }
                                </section>      
                                </>
                            }              
                                                    
                            </>
                    }
                    {(loading == 1) &&
                        <>
                            <div></div>
                        </>
                    }
                </div>


            </>
            , container
        )
    }
}
export default App;
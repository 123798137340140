
import * as React from "react";
import { LabelContext } from "../core/label.context";

import { GeneralContext } from "../core/general.context";

export const BannerTop = (item: any) => {

    const { setStart, isMobile } = React.useContext(GeneralContext);

    const { t } = React.useContext(LabelContext);
    interface IHeaderBanner {
        title: string;
        subTitle: string;
        image: string;
        imageDesktop: string;
        cta: string;
    }

    let headerBanner: IHeaderBanner = {
        title: t("headerTitle") || "",
        subTitle: t("headerSubTitle") || "",
        image: t("headerImage") || "",
        imageDesktop: t("headerImageDesktop") || "",
        cta: t("homeCta") || "",
    }

    return <>
        <section id="landing_bannerTop" className="bannerTop" data-section="1">
            {isMobile &&
            <>
                <div className="bannerTop_container">
                    <h1 className="title">{headerBanner.title}</h1>
                    <h2 className="subTitle">{headerBanner.subTitle}</h2>
                    <a className="cta" href="#" onClick={ () => setStart(1) }>{headerBanner.cta}</a>
                </div>
                <img className="gallery" src={headerBanner.image} />
            </>
            }
            {!isMobile &&
            <img className="gallery" src={headerBanner.imageDesktop} />
            }
        </section>
    </>
};

